<!-- CHECK: 미사용 추정 -->

<template>
    <div class="ld-container" style="height: 90vh; width: 100%">
        <div>
            <header-box-component :title="`${$t('건물 운영 관리')} > ${$t('운전 현황 및 제어')}`" />
        </div>

        <div class="card-container">
            <div class="row">
                <div v-for="(node) in sysNodeRootList" :key="node.sysNodeIdx" 
                    class="col-lg-4 col-md-6 col-12 mb-3 pointer-cursor" @click="goToDetail(node.sysNodeIdx)">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="card-left">
                                        <span class="card-title mb-0" style="font-size: 2rem;">
                                            <i :class="node.icon ?? ''"></i>
                                        </span>
                                        <span class="card-title mb-0" style="font-weight: 700; font-size: 0.9rem;">{{ node.sysType ?? '-' }}</span>
                                    </div>
                                    <div class="card-right">
                                        <span class="card-title" style="font-weight: 600; font-size: 0.9rem; ">{{ node.sysTypeName ?? '-' }}</span>
                                        <h6 class="card-subtitle text-muted">{{ node.description ?? '-' }}</h6>
                                    </div>
                                    <div class="system-stats">
                                        <span class="badge badge-secondary">{{ `Root: ${node.RootCnt ?? 0}` }}</span>
                                        <span class="badge badge-secondary">{{ `Group: ${node.GroupCnt ?? 0}` }}</span>
                                        <span class="badge badge-primary">{{ `Equip: ${node.EquipCnt ?? 0}` }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 아이콘 사이즈업 -->
    </div>
</template>

<script>
import HeaderBoxComponent from "@views/component/headerBox/analysis/Header.vue";
import backEndApi from '@src/api/backEndApi';

export default {
    components: {
        HeaderBoxComponent,
    },
    props: {
    },
    data() {
        return {
            sysNodeRootList: null,
            systemTypeList: null,
        };
    },
    computed: {},
    created() {
        this.getSysNodeRootData();
    },
    mounted() { },
    methods: {
        async getSysNodeRootData() {
            this.systemTypeList = this.$store.state.systemTypeList;
            let result = await backEndApi.sysNode.getRootNodeList("Y");
            if (result && result.data) {
                this.sysNodeRootList = this.systemTypeList.filter(type => result.data.some(node => node.sysType == type.sysType));
                this.sysNodeRootList.forEach(node => {
                    let find = result.data.find(item => item.sysType == node.sysType)
                    node.sysNodeIdx = find.sysNodeIdx;
                    node.RootCnt = find.counter.Root;
                    node.GroupCnt = find.counter.Group;
                    node.EquipCnt = find.counter.Equip;
                })
            }
        },
        goToDetail(sysNodeIdx) {
            this.$router.push({ name: 'EquipMgmtCtrl', params: { sysNodeIdx: sysNodeIdx } });
        }
    }
};
</script>

<style scoped>
.card-container {
    background-color: #d3d6db;
    height: 87vh;
    padding: 1rem;
    overflow-y: auto;
}

.card-left {
    /* border-radius: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 120px;
    border-right: 1px solid grey;
}

.card-right {
    /* border-radius: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 120px;
}

.card-subtitle {
    padding: 0px 10px 0px 10px;
}

.system-stats {
    display: flex;
    flex-direction: column;
}

.system-stats .badge {
    width: 5rem;
    height: 1.5rem;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.system-stats .badge-secondary {
    margin-bottom: 5px;
}
.card {
    border-radius: 10px;
    border: 1px solid grey;
    box-shadow: 5px 5px 5px grey;
}
</style>